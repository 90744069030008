.container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  padding: var(--item-padding-top) var(--item-padding-left);

  .header {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;

    gap: calc(var(--gap) * 5);
  }

  .info {
    width: 700px;

    display: flex;
    align-items: start;
    flex-direction: column;
    gap: calc(var(--gap) * 2);

    h1 {
      font-size: var(--font-xxl);
      color: var(--main-color)
    }
  }

  .card {
    width: 600px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: calc(var(--gap) * 8);

    border-radius: var(--border-radius);
    box-shadow: -20px -20px 16px -4px #1f6fff2e, 4px 4px 6px -2px #10182808;
    padding: calc(var(--item-padding-top) * 1.25) calc(var(--item-padding-left) * 2);

    .footer {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      gap: calc(var(--gap) * 1.5);

      .button {
        width: 100%;
      }
    }
  }
}
