.list {
  width: 100%;
  height: fit-content;
  max-height: 240px;

  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(var(--gap));

  padding-right: calc(var(--item-padding-left));
  padding-top: calc(var(--item-padding-top) / 4);

  .item {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: start;

    gap: calc(var(--gap));

    .input {
      width: 100%;

      display: flex;
      align-items: center;

      gap: calc(var(--gap));
    }

    .error {
      color: red;
    }
  }
}

.buttons {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: calc(var(--gap));
}

.svg {
  height: 40px;

  background-color: transparent;
  border: 2px solid var(--main-color);

  svg {
    fill: var(--main-color);
  }

  &:hover {
    background-color: var(--main-color);

    svg {
      fill: var(--main-background-color);
    }
  }
}

