.container {
  width: 100vw;
  height: calc(var(--header-height) * 1.25);
  display: flex;

  border-bottom: 2px solid var(--border-color);
  background-color: var(--main-background-color);

  position: fixed;
  top: 0;
  z-index: 1000;

  .profile {
    width: 175px; // было 310

    display: flex;
    align-items: center;
    justify-content: end;

    gap: calc(var(--gap) * 5);

    margin-right: calc(var(--item-padding-left) * 5);
    margin-left: calc(var(--item-padding-left) * 7);

    .limits {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-wrap: nowrap;
    }

    svg {
      display: flex;
      height: calc(var(--header-height) - 5px);
      max-height: 40px;
      width: calc(var(--header-height) - 10px);
    }
  }
}

.width {
  min-width: 150px;
}
