.text {
  display: -webkit-box;
  -webkit-line-clamp: 1; // количество строк
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: end;

  font-size: var(--font-size);

  .navigation {
    display: flex;
    height: 100%;

    gap: calc(var(--gap) * 5);

    li {
      width: 140px;

      position: relative;

      .children {
        position: absolute;
        top: calc(var(--header-height));
        width: 100%;
        background-color: var(--main-second-color);

        display: none;
        flex-direction: column;

        .child {
          height: var(--header-height);
          padding: var(--item-padding-top) var(--item-padding-left);
          text-align: center;

          transition: var(--transition);

          p {
            color: var(--font-color);
          }

          &:hover {
            p {
              opacity: 0.8;
            }
          }

          @extend .text;
        }
      }

      &:hover {
        .children {
          display: flex;
        }
      }
    }
  }
}

.headerItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  .expand {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--item-padding-left);
    height: 100%;
  }

  height: 100%;
  cursor: pointer;

  transition: var(--transition);

  &:hover {
    .border {
      opacity: 1;
    }
  }
}

.border {
  position: absolute;
  bottom: 0;
  width: 40%;
  border: 2.4px solid #002363;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  opacity: 0;
  transition: var(--transition);
}

.active__border {
  opacity: 1;
  width: 100%;
  @extend .border;
}

.logo {
  padding: 0 calc(var(--item-padding-left) * 5) 0 var(--page-padding-left);
  font-size: var(--font-xxl);
}
