.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: calc(var(--gap) * 2);

  .input {
    width: 300px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: calc(var(--gap));
  }
}
