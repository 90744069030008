.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  gap: calc(var(--gap) * 1.7);

  width: clamp(300px, 70vw, 600px);
  height: fit-content;

  header {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
