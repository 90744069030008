.container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: calc(var(--gap) * 3);

  ul {
    display: flex;
    flex-direction: column;
    gap: calc(var(--gap) * 3);

    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      gap: var(--gap);

      .question {
        width: 16px;
        height: 16px;

        position: relative;

        text-align: center;
        font-size: var(--font-s);
        color: white;

        background-color: var(--main-color);

        border-radius: 50%;

        user-select: none;
        cursor: pointer;

        .modal {
          z-index: 5000;
          display: none;

          width: 430px;
          padding: 12px;

          position: absolute;
          left: -360px;
          bottom: -17px;

          color: var(--font-color);
          text-align: justify;

          background-color: var(--main-background-color);
          border: 1px solid #000;

          border-radius: var(--border-radius);

          transform: translateY(-35px);
        }

        &:hover .modal {
          display: block;
        }
      }
    }
  }
}
