.container {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: calc(var(--header-height) * 1.25);
  overflow: hidden!important;
}

.loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
