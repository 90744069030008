.tariff {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: calc(var(--gap) * 3);
  }

  .price {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: calc(var(--gap) * 3);

    padding-top: calc(var(--item-padding-top) * 2.9);

    h1 {
      font-size: var(--font-xxl);
    }

    span {
      font-size: var(--font-xl);
    }
  }
}
