.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: calc(var(--gap) * 1.25);

  .button {
    width: 100%;
  }
}
