.container {
  width: 270px;
  height: 50px;

  .select {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-around;

    border: 1px solid var(--border-color);
    border-radius: calc(var(--border-radius) / 2);

    background-color: white;

    cursor: pointer;
  }

  .options {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(var(--gap) * 3);

    border: 1px solid var(--border-color);
    border-radius: calc(var(--border-radius) / 2);

    margin-top: var(--gap);
    padding: calc(var(--item-padding-left) / 1);

    background-color: white;

    cursor: pointer;

    .option {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}


.mark {
  width: 50px;

  text-align: center;
  font-size: var(--font-size);
  color: white;

  border-radius: calc(var(--border-radius) / 5);

  padding: calc(var(--item-padding-left) / 6);

  background-color: var(--main-color);
}
