.container {
  position: fixed;
  bottom: 20px;
  right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--main-background-color);
  font-weight: bold;
  font-size: var(--font-xxl);

  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--main-color);
}

.modal {
  position: fixed;
  bottom: 70px;
  right: 20px;

  padding: var(--item-padding-top) var(--item-padding-left);

  border: 1px solid var(--border-color);
  background-color: var(--main-background-color);

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  .link {
    display: flex;
    align-items: center;
    gap: var(--gap);

    svg {
      width: 24px;
    }
  }

  .additional {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap);

    padding-left: calc(var(--item-padding-left) * 1.75);
  }
}
