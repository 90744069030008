.lng {
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  p {
    border-bottom: 3px solid #0000003b;
  }
}

.lng_popup {
  position: absolute;
  bottom: -30px;
  background-color: var(--main-background-color);
  flex-direction: column;
  padding: 8px;
  border: 1px solid black;
  display: none;
}

.lng_open {
  display: flex;
}
