@mixin symbol($size, $textSize){
  width: $size;
  height: $size;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: $textSize;

  background-color: var(--second-background-color);

  border-radius: calc(var(--input-border-radius));
}

.container {
  position: fixed;
  top: 75px;
  right: 20px;
  width: 303px;
  background-color: white;
  border: 2px solid var(--border-color);
  box-shadow: var(--box-shadow);
  padding: 16px;
  z-index: 1000;
  display: none;

  &.show {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: calc(var(--gap) * 2);
  }

  .button {
    width: 100%;

    display: flex;
    align-items: center;

    gap: calc(var(--gap) * 2);
  }
}

.workspaces {
  width: 267px;

  display: flex;
  flex-direction: column;
  align-items: start;

  gap: calc(var(--gap) * 1.5);

  .current {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: start;

    gap: calc(var(--gap) * 1.5);

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;

      gap: calc(var(--gap) * 2);

      .item {
        display: flex;
        flex-direction: column;
        align-items: start;
      }

      span {
       @include symbol(40px, var(--font-xl))
      }
    }

    .bottom {
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: calc(var(--font-s));
      }
    }
  }

  .list {
    height: auto;

    background-color: var(--second-background-color);
    border-radius: calc(var(--input-border-radius));

    padding: calc(var(--item-padding-top) - 10px) calc(var(--item-padding-left) - 8px) calc(var(--item-padding-top) - 10px) calc(var(--item-padding-left) - 5px);
  }

  ul {
    width: 100%;
    max-height: 145px;

    display: flex;
    flex-direction: column;
    align-items: center;

    list-style-type: none;

    gap: calc(var(--gap));

    overflow-y: auto;

    li {
      width: 238px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      cursor: pointer;
    }

    .item {
      display: flex;
      align-items: center;

      gap: calc(var(--gap));
    }

    span {
      @include symbol(25px, var(--font-xl))
    }

    &::-webkit-scrollbar {
      width: 3.5px;
    }
  }

  b, svg {
    color: var(--second-font-color);
  }

  b {
    width: 165px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
