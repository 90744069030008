.button {
  border-radius: var(--border-radius);
  background-color: var(--main-color);
  color: var(--main-background-color);
  padding: var(--item-padding-top) var(--item-padding-left);
  transition: var(--transition);

  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  min-width: 100px;

  &:active {
    scale: 0.98;
  }

  &:hover {
    opacity: 0.95;
  }

  &:disabled {
    background-color: white;
    color: var(--font-color);
  }
}
