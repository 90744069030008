.container {
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  display: none;
  align-items: center;
  justify-content: center;
  
  z-index: 4000;
  
  background-color: rgba(0, 0, 0, 0.25);
  
  .modal {
    overflow-y: hidden;
    overflow-x: hidden;
    padding: var(--item-padding-top) var(--item-padding-left);
    background-color: var(--backdrop-сolor);

    border-radius: var(--border-radius);
    min-width: 270px;
    box-shadow: 4px 8px 14px 4px rgba(34, 60, 80, 0.25);
  }
}

.active {
  display: flex;
}
