.input {
  border: 2px solid var(--border-color);
  height: 40px;
  width: 100%;

  border-radius: var(--border-radius);
  transition: var(--transition);

  background-color: var(--backdrop-сolor);

  padding: var(--item-padding-top) var(--item-padding-left);

  &:active,
  &:focus,
  &:hover {
    border-color: var(--main-color);
  }

  &:disabled {
    background-color: var(--second-background-color);

    &:hover {
      border-color: var(--border-focus-color);
    }
  }
}

.icon {
  position: absolute;
  right: 10px;
  top: 0;
  transform: translateY(50%);

  svg {
    fill: #000;
  }
}

.error {
  background-color: rgba(234, 57, 67, 0.1);
  border-color: rgba(234, 57, 67, 0.4);
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  width: 100%;

  .helper {
    width: max-content;

    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);

    font-size: var(--font-s);

    color: var(--main-color);

    background-color: var(--main-background-color);;

    pointer-events: none;

    z-index: 10;
  }

  .placeholder {
    position: absolute;
    background-color: var(--main-background-color);
    top: -5px;
    color: var(--second-font-color);
    font-size: var(--font-xs);
    left: 15px;

    display: -webkit-box;
    -webkit-line-clamp: 1; // количество строк
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: fit-content;
  }

  .label {
    position: absolute;

    top: 50%;
    left: 8px;

    color: #999;

    background-color: var(--main-background-color);;

    pointer-events: none;

    transform: translateY(-50%);
    transition: all 0.2s ease;

    z-index: 10;
  }

  .focused {
    top: 7px;

    font-size: var(--font-s);

    color: var(--main-color);

    transform: translateY(-100%);
  }
}

.input__container {
  position: relative;

  input {
    padding-right: 30px;
  }
}
