.container {
  width: 600px;

  display: flex;
  align-items: start;
  flex-direction: column;
  gap: calc(var(--gap) * 2);

  h1 {
    font-size: var(--font-xxl);
    color: var(--main-color)
  }
}
